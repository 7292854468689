import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Approve = ({ color }: Props) => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.25879 15.2763L10.7633 18.5365C11.0426 18.7136 11.4363 18.6719 11.6521 18.4532L20.5631 9.08447'
      stroke={color}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
  </svg>
)

export default Approve
