import React, { CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components'

type Padding =
  | CSSProperties['padding']
  | {
      horizontal?: CSSProperties['padding']
      vertical?: CSSProperties['padding']
      left?: CSSProperties['padding']
      top?: CSSProperties['padding']
      right?: CSSProperties['padding']
      bottom?: CSSProperties['padding']
    }

type Margin =
  | CSSProperties['margin']
  | {
      horizontal?: CSSProperties['margin']
      vertical?: CSSProperties['margin']
      left?: CSSProperties['margin']
      top?: CSSProperties['margin']
      right?: CSSProperties['margin']
      bottom?: CSSProperties['margin']
    }

type SharedProps = {
  inline?: boolean
  column?: boolean
  wrap?: boolean | CSSProperties['flexWrap']
  gap?: CSSProperties['gap']
  center?: boolean | 'horizontally' | 'vertically'
  alignItems?: CSSProperties['alignItems']
  justifyContent?: CSSProperties['justifyContent']
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  padding?: Padding
  margin?: Margin
  flexGrow?: CSSProperties['flexGrow']
  flexShrink?: CSSProperties['flexShrink']
  flexBasis?: CSSProperties['flexBasis']
}

export type Props = SharedProps & {
  children?: ReactNode
  className?: string
}

const Flex = ({
  children,
  className,
  inline,
  column,
  wrap,
  gap,
  center,
  alignItems,
  justifyContent,
  width,
  height,
  padding,
  margin,
  flexGrow,
  flexShrink,
  flexBasis,
}: Props) => {
  return (
    <StyledDiv
      className={className}
      $inline={inline}
      $column={column}
      $wrap={wrap}
      $gap={gap}
      $center={center}
      $alignItems={alignItems}
      $justifyContent={justifyContent}
      $width={width}
      $height={height}
      $padding={padding}
      $margin={margin}
      $flexGrow={flexGrow}
      $flexShrink={flexShrink}
      $flexBasis={flexBasis}
    >
      {children}
    </StyledDiv>
  )
}

export default Flex

type StyledProps = {
  [Property in keyof SharedProps as `$${Property}`]: SharedProps[Property]
}

const StyledDiv = styled.div<StyledProps>`
  display: ${({ $inline }) => ($inline ? 'inline-flex' : 'flex')};

  flex-direction: ${({ $column }) => ($column ? 'column' : 'row')};

  flex-wrap: ${({ $wrap }) => ($wrap === true ? 'wrap' : $wrap)};

  ${({ $gap }) => ({ gap: $gap })}

  ${({ $alignItems, $center, $column }) => {
    if ($alignItems) {
      return css`
        align-items: ${$alignItems};
      `
    } else if ($column && ($center === true || $center === 'horizontally')) {
      return css`
        align-items: center;
      `
    } else if (!$column && ($center === true || $center === 'vertically')) {
      return css`
        align-items: center;
      `
    }
  }}

  ${({ $justifyContent, $center, $column }) => {
    if ($justifyContent) {
      return css`
        justify-content: ${$justifyContent};
      `
    } else if ($column && ($center === true || $center === 'vertically')) {
      return css`
        justify-content: center;
      `
    } else if (!$column && ($center === true || $center === 'horizontally')) {
      return css`
        justify-content: center;
      `
    }
  }}
  
  ${({ $width }) => ({ width: $width })}

  ${({ $height }) => ({ height: $height })}

  ${({ $padding }) => {
    if (typeof $padding === 'object')
      return {
        paddingTop: $padding.top || $padding.vertical,
        paddingBottom: $padding.bottom || $padding.vertical,
        paddingLeft: $padding.left || $padding.horizontal,
        paddingRight: $padding.right || $padding.horizontal,
      }
    return { padding: $padding }
  }}

  ${({ $margin }) => {
    if (typeof $margin === 'object')
      return {
        paddingTop: $margin.top || $margin.vertical,
        paddingBottom: $margin.bottom || $margin.vertical,
        paddingLeft: $margin.left || $margin.horizontal,
        paddingRight: $margin.right || $margin.horizontal,
      }
    return { margin: $margin }
  }}

  ${({ $flexGrow }) => ({ flexGrow: $flexGrow })}

  ${({ $flexShrink }) => ({ flexShrink: $flexShrink })}

  ${({ $flexBasis }) => ({ flexBasis: $flexBasis })}
`
