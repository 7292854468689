import { BreakpointType, breakpointNumbers, newBreakpointNumbers } from 'theme'
import { useEffect, useState } from 'react'

import { useMediaQuery } from 'react-responsive'

type UseBreakpointProps = {
  type?: 'default' | 'new'
}

const useBreakpoint = ({ type = 'default' }: UseBreakpointProps = {}) => {
  const [activeBreakpoint, setActiveBreakpoint] = useState<BreakpointType>('xl') // Default to 'xl'

  // Convert em values to pixels assuming 1em = 16px
  const pxBreakpoints = Object.fromEntries(Object.entries(breakpointNumbers).map(([key, value]) => [key, value * 16]))
  const newPxBreakpoints = Object.fromEntries(
    Object.entries(newBreakpointNumbers).map(([key, value]) => [key, value * 16]),
  )

  const isXs = useMediaQuery({ maxWidth: pxBreakpoints.sm - 1 }) // Extra small devices, above 375px.
  const isSm = useMediaQuery({ minWidth: pxBreakpoints.sm - 1, maxWidth: pxBreakpoints.md - 1 }) //  Small devices, above 744px.
  const isMd = useMediaQuery({ minWidth: pxBreakpoints.md - 1, maxWidth: pxBreakpoints.lg - 1 }) // Medium devices, above 1024px.
  const isLg = useMediaQuery({ minWidth: pxBreakpoints.lg - 1, maxWidth: pxBreakpoints.xl - 1 }) // Large devices, above 1280px.
  const isXl = useMediaQuery({ minWidth: pxBreakpoints.xl }) // Extra large devices, above 1560px.

  const isNewXs = useMediaQuery({ maxWidth: newPxBreakpoints.sm - 1 }) // Extra small devices, above 375px.
  const isNewSm = useMediaQuery({ minWidth: newPxBreakpoints.sm - 1, maxWidth: newPxBreakpoints.md - 1 }) //  Small devices, above 744px.
  const isNewMd = useMediaQuery({ minWidth: newPxBreakpoints.md - 1, maxWidth: newPxBreakpoints.lg - 1 }) // Medium devices, above 1024px.
  const isNewLg = useMediaQuery({ minWidth: newPxBreakpoints.lg }) // Large devices, 1800px and above.

  useEffect(() => {
    const breakpoint = setTimeout(() => {
      if (type === 'new') {
        if (isNewXs) setActiveBreakpoint('xs')
        else if (isNewSm) setActiveBreakpoint('sm')
        else if (isNewMd) setActiveBreakpoint('md')
        else if (isNewLg) setActiveBreakpoint('lg')
      }

      if (type === 'default') {
        if (isXs) setActiveBreakpoint('xs')
        else if (isSm) setActiveBreakpoint('sm')
        else if (isMd) setActiveBreakpoint('md')
        else if (isLg) setActiveBreakpoint('lg')
        else if (isXl) setActiveBreakpoint('xl')
      }
    }, 0)

    return () => clearTimeout(breakpoint)
  }, [isXs, isSm, isMd, isLg, isXl, isNewXs, isNewSm, isNewMd, isNewLg, type])

  return activeBreakpoint
}

export default useBreakpoint
