import React, { ReactNode } from 'react'

import IconButton from '../IconButton'
import Overlay from '../Overlay'
import Text from '../Text'
import styled from 'styled-components'

type Props = {
  className?: string
  title: string
  children: ReactNode
  onClose: () => void
  closeOnMask?: boolean
}

const Modal = ({ className, title, closeOnMask, children, onClose }: Props) => (
  <Overlay>
    <Wrapper className={className} onClick={() => (closeOnMask ? onClose() : null)}>
      <Container onClick={(e) => e.stopPropagation()}>
        <Header>
          <Title tag='h4' color='charcoal'>
            {title}
          </Title>
          <CloseButton>
            <IconButton type='close' scheme='light' onClick={onClose} />
          </CloseButton>
        </Header>
        <Divider />
        {children}
      </Container>
    </Wrapper>
  </Overlay>
)

export default Modal

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media ${({ theme }) => theme.breakpoints.sm} {
    align-items: center;
  }
`

const Container = styled.div`
  min-width: 343px;
  position: absolute;
  bottom: 16px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.charcoal10};
  box-shadow: 0px 25px 45px 0px rgba(55, 53, 48, 0.2);
  background: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.breakpoints.sm} {
    bottom: unset;
  }
`

const Header = styled.div`
  display: flex;
  gap: 8px;
`

const Title = styled(Text)`
  flex: 1;
  width: 0;
  padding: 24px 0 24px 24px;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 32px 0 32px 32px;
  }
`

const CloseButton = styled.div`
  padding: 16px 16px 16px 0;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.charcoal10};
`
