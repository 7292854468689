import React, { ReactNode } from 'react'

import styled from 'styled-components'

type Props = {
  children: ReactNode
}

const NewCentraliser = ({ children }: Props) => (
  <Container>
    <Content>{children}</Content>
  </Container>
)

export default NewCentraliser

const Container = styled.div`
  width: 100%;
  max-width: 1640px;
  margin: auto;
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  width: 343px;
  margin: 0 16px;
  position: relative;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    width: 680px;
    margin: 0 32.5px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: 960px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: 1400px;
    margin: 0 80.5px;
  }
`
