import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const TickLarge = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path d='M4 12L9 17L19 7' stroke={color} strokeWidth='2' />
  </svg>
)

export default TickLarge
