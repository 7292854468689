import React from 'react'

const TickBlue = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='16' height='16' rx='8' fill='#005EEB' />
    <path d='M3 8L6.33333 11L13 5' stroke='white' strokeWidth='1.5' />
  </svg>
)

export default TickBlue
