import { ColorType, SchemeType } from 'theme'
import React, { MouseEvent } from 'react'

import Icon from '../Icon'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useBlockScheme } from '../Block'

type Props = {
  scheme?: SchemeType
  value: boolean
  locked?: boolean
  disabled?: boolean
  onChange?: (e: MouseEvent<HTMLButtonElement>, value: boolean) => void
}

const Toggle = ({ scheme, value, locked, disabled, onChange }: Props) => {
  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme
  const valueName = value ? 'on' : 'off'
  const iconName = locked ? 'lock' : valueName

  const variants = {
    on: { x: '100%' },
    off: { x: 0 },
  }

  const onChangeHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (locked || disabled) return

    onChange?.(e, !value)
  }

  return (
    <div>
      <Container
        onClick={onChangeHandler}
        $scheme={colorScheme}
        $active={value}
        $locked={!!locked}
        $disabled={!!disabled}
      >
        <Button
          variants={variants}
          initial={value ? 'on' : 'off'}
          animate={value ? 'on' : 'off'}
          transition={{ type: 'spring', stiffness: 370, mass: 2, damping: 38 }}
        >
          {!disabled && <Icon name={iconName} />}
        </Button>
      </Container>
    </div>
  )
}

export default Toggle

type ToggleSchemeType = {
  [S in SchemeType]: {
    svgIdleColor: ColorType
    svgHoverColor: ColorType
    svgClickColor: ColorType
    idleColor: ColorType
    hoverColor: ColorType
    clickColor: ColorType
    activeColor: ColorType
    disabledColor: ColorType
  }
}

const TOGGLE_COLOR_SCHEME: ToggleSchemeType = {
  light: {
    svgIdleColor: 'charcoal20',
    svgHoverColor: 'charcoal40',
    svgClickColor: 'charcoal60',
    idleColor: 'charcoal20',
    hoverColor: 'charcoal40',
    clickColor: 'charcoal60',
    activeColor: 'blue',
    disabledColor: 'charcoal10',
  },
  dark: {
    svgIdleColor: 'navyDark',
    svgHoverColor: 'navyDark',
    svgClickColor: 'navyDark',
    idleColor: 'white20',
    hoverColor: 'white30',
    clickColor: 'white40',
    activeColor: 'blue',
    disabledColor: 'white10',
  },
}

type StyledToggleProps = {
  $scheme: SchemeType
  $active: boolean
  $locked: boolean
  $disabled: boolean
}

const Button = styled(motion.div)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  background: white;

  svg > path {
    transition: ${({ theme }) => theme.transitions.toggle.fill};
  }
`

const Container = styled.button<StyledToggleProps>`
  all: unset;
  width: 44px;
  height: 24px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: ${({ $scheme, theme }) => theme.colors[TOGGLE_COLOR_SCHEME[$scheme].idleColor]};
  background: ${({ $scheme, $active, theme }) => $active && theme.colors[TOGGLE_COLOR_SCHEME[$scheme].activeColor]};
  background: ${({ $scheme, $disabled, theme }) =>
    $disabled && theme.colors[TOGGLE_COLOR_SCHEME[$scheme].disabledColor]};
  transition: ${({ theme }) => theme.transitions.toggle.background};
  pointer-events: ${({ $disabled, $locked }) => ($locked || $disabled) && 'none'};
  cursor: pointer;

  ${Button} {
    svg > path {
      fill: ${({ $scheme, theme }) => theme.colors[TOGGLE_COLOR_SCHEME[$scheme].svgIdleColor]};
      fill: ${({ $scheme, $active, theme }) => $active && theme.colors[TOGGLE_COLOR_SCHEME[$scheme].activeColor]};
    }
  }

  :hover {
    background: ${({ $scheme, $active, $locked, theme }) =>
      !$active && !$locked && theme.colors[TOGGLE_COLOR_SCHEME[$scheme].hoverColor]};

    ${Button} {
      svg > path {
        fill: ${({ $scheme, $active, $locked, theme }) =>
          !$active && !$locked && theme.colors[TOGGLE_COLOR_SCHEME[$scheme].svgHoverColor]};
      }
    }
  }

  :active {
    background: ${({ $scheme, theme }) => theme.colors[TOGGLE_COLOR_SCHEME[$scheme].clickColor]};

    ${Button} {
      svg > path {
        fill: ${({ $scheme, $active, $locked, theme }) =>
          (!$active || !$locked) && theme.colors[TOGGLE_COLOR_SCHEME[$scheme].svgClickColor]};
      }
    }
  }

  :focus-visible::before {
    content: '';
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border: 2px solid ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
    border-radius: 38px;
  }
`
