import React, { ReactNode } from 'react'
import styled, { CSSProperties } from 'styled-components'

type Padding =
  | CSSProperties['padding']
  | {
      horizontal?: CSSProperties['padding']
      vertical?: CSSProperties['padding']
      left?: CSSProperties['padding']
      top?: CSSProperties['padding']
      right?: CSSProperties['padding']
      bottom?: CSSProperties['padding']
    }

type Margin =
  | CSSProperties['margin']
  | {
      horizontal?: CSSProperties['margin']
      vertical?: CSSProperties['margin']
      left?: CSSProperties['margin']
      top?: CSSProperties['margin']
      right?: CSSProperties['margin']
      bottom?: CSSProperties['margin']
    }

type ElementProps = {
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  gap?: CSSProperties['gap']
  rowGap?: CSSProperties['rowGap']
  columnGap?: CSSProperties['columnGap']
  padding?: Padding
  margin?: Margin
  gridTemplateColumns?: CSSProperties['gridTemplateColumns']
  gridTemplateRows?: CSSProperties['gridTemplateRows']
  gridColumn?: CSSProperties['gridColumn']
  gridRow?: CSSProperties['gridRow']
}

type Props = ElementProps & {
  className?: string
  children: ReactNode
}

const BaseGrid = ({
  className,
  width,
  height,
  gap,
  rowGap,
  columnGap,
  gridTemplateColumns,
  gridTemplateRows,
  gridColumn,
  gridRow,
  margin,
  padding,
  children,
}: Props) => (
  <Container
    className={className}
    $width={width}
    $height={height}
    $gap={gap}
    $rowGap={rowGap}
    $columnGap={columnGap}
    $gridTemplateColumns={gridTemplateColumns}
    $gridTemplateRows={gridTemplateRows}
    $gridColumn={gridColumn}
    $gridRow={gridRow}
    $margin={margin}
    $padding={padding}
  >
    {children}
  </Container>
)

export default BaseGrid

type ContainerProps = {
  [Property in keyof ElementProps as `$${Property}`]: ElementProps[Property]
}
const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  }

  ${({ $padding }) => {
    if (typeof $padding === 'object')
      return {
        paddingTop: $padding.top || $padding.vertical,
        paddingBottom: $padding.bottom || $padding.vertical,
        paddingLeft: $padding.left || $padding.horizontal,
        paddingRight: $padding.right || $padding.horizontal,
      }
    return { padding: $padding }
  }};

  ${({ $margin }) => {
    if (typeof $margin === 'object')
      return {
        paddingTop: $margin.top || $margin.vertical,
        paddingBottom: $margin.bottom || $margin.vertical,
        paddingLeft: $margin.left || $margin.horizontal,
        paddingRight: $margin.right || $margin.horizontal,
      }
    return { margin: $margin }
  }};

  ${({ $width }) => ({ width: $width })};
  ${({ $height }) => ({ height: $height })};

  ${({ $gridColumn }) => ({ gridColumn: $gridColumn })};
  ${({ $gridRow }) => ({ gridRow: $gridRow })};
  ${({ $gridTemplateColumns }) => ({ gridTemplateColumns: $gridTemplateColumns })};
  ${({ $gridTemplateRows }) => ({ gridTemplateRows: $gridTemplateRows })};
  ${({ $columnGap }) => ({ columnGap: $columnGap })};
  ${({ $rowGap }) => ({ rowGap: $rowGap })};
`
