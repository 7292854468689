import React, { ReactNode } from 'react'

import NewCentraliser from './NewCentraliser'
import styled from 'styled-components'

type BreakpointType = 'new' | 'default'

type Props = {
  children: ReactNode
  type?: BreakpointType
}

const Centraliser = ({ children, type = 'default' }: Props) => (
  <Container>{type === 'new' ? <NewCentraliser>{children}</NewCentraliser> : <Content>{children}</Content>}</Container>
)

export default Centraliser

const Container = styled.div`
  width: 100%;
  max-width: 1640px;
  margin: auto;
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  width: 343px;
  margin: 0 16px;
  position: relative;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 680px;
    margin: 0 32.5px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 960px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 1200px;
    margin: 0 40.5px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    width: 1400px;
    margin: 0 80.5px;
  }
`
