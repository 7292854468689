import { ColorType } from 'theme'
import React from 'react'

type Props = { color?: ColorType }

const TickBox = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' fill='none' viewBox='0 0 25 24'>
    <g stroke={color} strokeLinecap='round' strokeMiterlimit='10' strokeWidth='1.25' clipPath='url(#clip0_10024_634)'>
      <path d='M9.174 11.463l2.72 3.082a.497.497 0 00.69-.079l7.795-9.856'></path>
      <path d='M19.305 13.098v4.204c0 1.152-.985 2.087-2.197 2.087H6.809c-1.212 0-2.198-.935-2.198-2.087V6.963c0-1.152.986-2.087 2.198-2.087h6.702'></path>
    </g>
    <defs>
      <clipPath id='clip0_10024_634'>
        <path fill={color} d='M0 0H17V16H0z' transform='translate(4 4)'></path>
      </clipPath>
    </defs>
  </svg>
)

export default TickBox
