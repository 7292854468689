import NextImage from 'next/image'
import OnetraceLogoIcon from 'public/library/images/onetrace-logo-icon.svg'
import OnetraceLogoIconLarge from 'public/library/images/onetrace-logo-icon-large.svg'
import OnetraceLogoTypeDark from 'public/library/images/onetrace-logo-type-dark.svg'
import OnetraceLogoTypeLight from 'public/library/images/onetrace-logo-type-light.svg'
import React from 'react'
import { SchemeType } from 'theme'
import styled from 'styled-components'
import { useBlockScheme } from '../Block'

export type LogoType = keyof typeof Logos

type Props = {
  className?: string
  name: LogoType
  scheme?: SchemeType
  alt?: string
}

export const OnetraceLogo = ({ name, scheme, alt = 'Onetrace' }: Props) => {
  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme

  return (
    <Container>
      {name === 'onetrace-logo-icon' && <NextImage src={Logos[name][colorScheme]} alt={alt} priority />}

      {name === 'onetrace-logo' && (
        <Wrapper>
          <NextImageLogoIcon src={OnetraceLogoIcon} alt={alt} priority />
          <NextImageLogoType src={Logos[name][colorScheme]} alt={alt} priority />
        </Wrapper>
      )}
    </Container>
  )
}

OnetraceLogo.displayName = 'OnetraceLogo'

export default OnetraceLogo

export const Logos = {
  'onetrace-logo': {
    light: OnetraceLogoTypeLight,
    dark: OnetraceLogoTypeDark,
  },
  'onetrace-logo-icon': {
    light: OnetraceLogoIconLarge,
    dark: OnetraceLogoIconLarge,
  },
} as const

const Container = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`

const Wrapper = styled.div`
  position: relative;
  display: contents;
`

const NextImageLogoIcon = styled(NextImage)`
  transition: ${({ theme }) => theme.transitions.default};

  ${Wrapper}:hover & {
    transform: scale(1.15);
  }
`

const NextImageLogoType = styled(NextImage)`
  margin-top: -3px;

  &:hover ~ ${NextImageLogoIcon} {
    transform: scale(1.15);
  }
`
