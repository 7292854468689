import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Plus = ({ color }: Props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M7 12H17' stroke={color} strokeWidth='1.25' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M12 17L12 7' stroke={color} strokeWidth='1.25' strokeMiterlimit='10' strokeLinecap='round' />
  </svg>
)

export default Plus
