import { ColorType, theme } from 'theme'
import Icons, { IconType } from '../../icons'
import React, { cloneElement } from 'react'

import styled from 'styled-components'

type Props = {
  className?: string
  name: IconType
  color?: ColorType
}

const Icon = ({ className, name, color = 'blue' }: Props) => (
  <Container className={className}>
    {cloneElement(Icons[name], {
      color: theme.colors[color],
    })}
  </Container>
)

export default Icon

const Container = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
`
