import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Off = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
    <path
      fill={color}
      d='M8.538 9.99c0 .518-.094.963-.284 1.336-.19.37-.449.656-.779.857a2.119 2.119 0 01-1.12.298c-.42 0-.794-.1-1.124-.298a2.088 2.088 0 01-.776-.86c-.19-.372-.285-.816-.285-1.332 0-.518.095-.962.285-1.333.189-.373.448-.658.776-.857.33-.2.705-.301 1.125-.301.418 0 .791.1 1.12.3.33.2.589.485.778.858.19.37.284.815.284 1.333zm-.724 0c0-.394-.064-.726-.192-.996a1.4 1.4 0 00-.52-.616c-.22-.14-.469-.21-.746-.21-.28 0-.53.07-.749.21a1.42 1.42 0 00-.52.616c-.127.27-.19.602-.19.997 0 .394.063.727.19.999.127.27.301.475.52.615.22.139.47.208.749.208.277 0 .526-.069.745-.208.221-.14.395-.346.521-.615.128-.272.192-.605.192-1zM9.45 12.415V7.566h3.007v.63h-2.275v1.477h2.06v.628h-2.06v2.114H9.45zM13.357 12.415V7.566h3.006v.63h-2.275v1.477h2.06v.628h-2.06v2.114h-.731z'
    ></path>
  </svg>
)

export default Off
