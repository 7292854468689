import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Time = ({ color }: Props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M12 8L12 12' stroke={color} strokeWidth='1.25' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M14 14L12 12' stroke={color} strokeWidth='1.25' strokeMiterlimit='10' strokeLinecap='round' />
    <path
      d='M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z'
      stroke={color}
      strokeWidth='1.25'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M16 18L15.1025 20.356C14.9548 20.7438 14.5829 21 14.168 21L9.83201 21C9.41707 21 9.04524 20.7438 8.89752 20.356L8 18'
      stroke={color}
      strokeWidth='1.25'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M8 6L8.89752 3.64401C9.04524 3.25625 9.41707 3 9.83201 3L14.168 3C14.5829 3 14.9548 3.25625 15.1025 3.644L16 6'
      stroke={color}
      strokeWidth='1.25'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
  </svg>
)

export default Time
