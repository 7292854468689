import Approve from './Approve'
import Burger from './Burger'
import CaretDown from './CaretDown'
import CaretDownSmall from './CaretDownSmall'
import CaretLeft from './CaretLeft'
import CaretRight from './CaretRight'
import CaretUpSmall from './CaretUpSmall'
import Cross from './Cross'
import Facebook from './Facebook'
import Form from './Form'
import Instagram from './Instagram'
import LinkedIn from './LinkedIn'
import Lock from './Lock'
import Menu from './Menu'
import Minus from './Minus'
import Off from './Off'
import On from './On'
import Payment from './Payment'
import Photo from './Photo'
import PinAdd from './PinAdd'
import Plus from './Plus'
import Productivity from './Productivity'
import Projects from './Projects'
import RightArrow from './RightArrow'
import Scan from './Scan'
import Signature from './Signature'
import Tick from './Tick'
import TickBlue from './TickBlue'
import TickBox from './TickBox'
import TickLarge from './TickLarge'
import Time from './Time'
import X from './X'

const Icons = {
  approve: <Approve />,
  burger: <Burger />,
  'caret-down-small': <CaretDownSmall />,
  'caret-down': <CaretDown />,
  'caret-left': <CaretLeft />,
  'caret-right': <CaretRight />,
  'caret-up-small': <CaretUpSmall />,
  cross: <Cross />,
  facebook: <Facebook />,
  form: <Form />,
  instagram: <Instagram />,
  linkedin: <LinkedIn />,
  lock: <Lock />,
  menu: <Menu />,
  minus: <Minus />,
  off: <Off />,
  on: <On />,
  payment: <Payment />,
  'pin-add': <PinAdd />,
  photo: <Photo />,
  plus: <Plus />,
  productivity: <Productivity />,
  projects: <Projects />,
  'right-arrow': <RightArrow />,
  scan: <Scan />,
  signature: <Signature />,
  'tick-blue': <TickBlue />,
  'tick-box': <TickBox />,
  'tick-large': <TickLarge />,
  tick: <Tick />,
  time: <Time />,
  x: <X />,
}

export default Icons

export type IconType = keyof typeof Icons
