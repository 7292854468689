import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Projects = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
      d='M9.742 18.243L24.002 28l14.147-9.677'
    ></path>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
      d='M9.838 17.116l13-9.286a2 2 0 012.325 0l13 9.286A2 2 0 0139 18.744V29.66a2 2 0 01-.869 1.65l-13 8.913a2 2 0 01-2.262 0l-13-8.914A2 2 0 019 29.66V18.745a2 2 0 01.838-1.628zM24 28v12.357'
    ></path>
  </svg>
)

export default Projects
