import React, { ReactNode, forwardRef } from 'react'

import BlockSchemeContext from './BlockSchemeContext'
import { SchemeType } from 'theme'
import styled from 'styled-components'

type CustomScheme = SchemeType | 'transparent'

type Props = {
  className?: string
  zIndex?: number
  scheme: CustomScheme
  children: ReactNode
}

const Block = forwardRef<HTMLDivElement, Props>(({ className, zIndex, scheme, children }, ref) => {
  const _scheme = scheme === 'transparent' ? 'light' : scheme

  return (
    <BlockSchemeContext.Provider value={_scheme}>
      <Container
        ref={ref}
        className={className}
        data-scheme={_scheme}
        $scheme={scheme as SchemeType}
        $isTransparent={scheme === 'transparent'}
        $zIndex={zIndex}
      >
        {children}
      </Container>
    </BlockSchemeContext.Provider>
  )
})

Block.displayName = 'Block'

export default Block

type StyledContainerProps = {
  $scheme: SchemeType
  $isTransparent: boolean
  $zIndex?: number
}

const Container = styled.div<StyledContainerProps>`
  width: 100%;
  position: relative;
  z-index: ${({ $zIndex }) => $zIndex};
  background: ${({ $scheme, theme }) => theme.schemeColor[$scheme]};
  background: ${({ $isTransparent }) => $isTransparent && 'transparent'};
`
