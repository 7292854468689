import { MotionProps, motion } from 'framer-motion'
import React, { ReactNode, useEffect } from 'react'

import styled from 'styled-components'

type Props = {
  className?: string
  allowPropagation?: boolean
  overflow?: boolean
  children: ReactNode
} & MotionProps

const Overlay = ({ className, allowPropagation, overflow, children, ...otherProps }: Props) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    document.documentElement.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
      document.documentElement.style.overflow = ''
    }
  }, [])

  return (
    <Mask
      className={className}
      onClick={allowPropagation ? undefined : (e) => e.stopPropagation()}
      {...otherProps}
      $overflow={overflow}
    >
      {children}
    </Mask>
  )
}

export default Overlay

const Mask = styled(motion.div)<{ $overflow?: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.mask};
  background: ${({ theme }) => theme.colors.mask};
  backdrop-filter: blur(12px);
  overflow: ${({ $overflow }) => $overflow && 'auto'};
`
