import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Productivity = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
      d='M8.5 26s16-3 30-18M30.5 8H38a.5.5 0 01.5.5V16'
    ></path>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M20.5 40V29.784M13.5 40v-6M27.5 40V25.892M34.5 40V22M9.5 40h30'
    ></path>
  </svg>
)

export default Productivity
