import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Scan = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' fill='none' viewBox='0 0 25 24'>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='1.25'
      d='M5.667 14v3.5a1.5 1.5 0 001.5 1.5h3.5M10.667 5h-3.5a1.5 1.5 0 00-1.5 1.5V10M19.667 10V6.5a1.5 1.5 0 00-1.5-1.5h-3.5M14.667 19h3.5a1.5 1.5 0 001.5-1.5V14M9.167 12h7'
    ></path>
  </svg>
)
export default Scan
