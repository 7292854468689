import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Instagram = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M12.006 2.98c2.941 0 3.29.014 4.446.065 1.075.048 1.655.228 2.042.378.512.198.882.439 1.264.821.387.387.624.752.821 1.263.15.387.331.972.379 2.041.051 1.16.064 1.509.064 4.443 0 2.94-.013 3.287-.064 4.443-.047 1.075-.228 1.655-.379 2.041-.197.512-.438.881-.82 1.264a3.386 3.386 0 01-1.265.82c-.387.15-.971.331-2.042.378-1.16.052-1.51.065-4.446.065-2.94 0-3.289-.013-4.445-.064-1.075-.048-1.655-.228-2.042-.379a3.405 3.405 0 01-1.264-.82 3.383 3.383 0 01-.822-1.264c-.15-.386-.33-.97-.378-2.04-.051-1.16-.064-1.509-.064-4.444 0-2.939.013-3.287.064-4.443.047-1.074.228-1.654.378-2.04.198-.512.439-.881.822-1.264a3.386 3.386 0 011.264-.82c.387-.15.971-.331 2.042-.379 1.156-.051 1.505-.064 4.445-.064zm0-1.98c-2.988 0-3.362.013-4.535.064-1.17.052-1.974.241-2.67.512a5.372 5.372 0 00-1.952 1.272 5.39 5.39 0 00-1.273 1.946c-.27.7-.46 1.5-.512 2.669C1.013 8.64 1 9.013 1 12c0 2.986.013 3.36.064 4.533.052 1.169.241 1.973.512 2.669.284.726.658 1.34 1.273 1.95a5.38 5.38 0 001.947 1.268c.701.27 1.5.46 2.67.511 1.174.052 1.548.065 4.536.065 2.988 0 3.362-.013 4.536-.065 1.17-.051 1.973-.24 2.67-.511a5.38 5.38 0 001.948-1.268c.61-.61.988-1.224 1.268-1.946.27-.7.46-1.5.512-2.668.051-1.174.064-1.547.064-4.534 0-2.986-.013-3.36-.064-4.533-.052-1.169-.241-1.972-.512-2.668a5.152 5.152 0 00-1.26-1.955 5.381 5.381 0 00-1.948-1.268c-.7-.27-1.5-.46-2.67-.511C15.37 1.013 14.996 1 12.007 1z'
    ></path>
    <path
      fill={color}
      d='M12.007 6.35A5.653 5.653 0 006.353 12a5.653 5.653 0 0011.307 0c0-3.12-2.532-5.65-5.653-5.65zm0 9.315A3.667 3.667 0 018.339 12a3.667 3.667 0 017.335 0 3.667 3.667 0 01-3.667 3.665zM19.205 6.126a1.32 1.32 0 11-2.64-.001 1.32 1.32 0 012.64.001z'
    ></path>
  </svg>
)

export default Instagram
