import { useEffect, useState } from 'react'

type BrowserType = 'Internet Explorer' | 'Edge' | 'Opera' | 'Firefox' | 'Safari' | 'Chrome' | 'Unknown'

const useBrowserType = (): BrowserType => {
  const [browserType, setBrowserType] = useState<BrowserType>('Unknown')

  useEffect(() => {
    const userAgent = navigator.userAgent

    // Browser detection logic
    if (/MSIE|Trident/.test(userAgent)) {
      setBrowserType('Internet Explorer')
    } else if (/Edg/.test(userAgent)) {
      setBrowserType('Edge')
    } else if (/Opera|OPR/.test(userAgent)) {
      setBrowserType('Opera')
    } else if (/Firefox/.test(userAgent)) {
      setBrowserType('Firefox')
    } else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
      setBrowserType('Safari')
    } else if (/Chrome/.test(userAgent) && !/Edg/.test(userAgent)) {
      setBrowserType('Chrome')
    } else {
      setBrowserType('Unknown')
    }
  }, [])

  return browserType
}

export default useBrowserType
