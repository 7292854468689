import { ColorType, SchemeType } from 'theme'
import React, { MouseEvent, ReactNode } from 'react'

import NextLink from 'next/link'
import openIntercom from 'helpers/openIntercom'
import styled from 'styled-components'
import { useBlockScheme } from '../Block'

export type ButtonType = 'button' | 'submit' | 'reset'

export type ButtonVariant = 'primary' | 'secondary'

export type ButtonSize = 'large' | 'small'

type ButtonConditionalProps =
  | {
      href: string
      onClick?: never
    }
  | {
      href?: never
      onClick?: (e: MouseEvent<HTMLButtonElement>) => void
    }

type Props = ButtonConditionalProps & {
  id?: string
  className?: string
  type?: ButtonType
  variant?: ButtonVariant
  size?: ButtonSize
  scheme?: SchemeType
  block?: boolean
  loading?: boolean
  disabled?: boolean
  children: ReactNode
}

const Button = ({
  id,
  className,
  href,
  type = 'button',
  variant = 'primary',
  size = 'large',
  scheme,
  block = false,
  loading,
  disabled,
  onClick,
  children,
}: Props) => {
  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme

  const Component = () => (
    <Container
      id={id}
      className={className}
      type={type}
      $variant={variant}
      $size={size}
      $scheme={colorScheme}
      $block={block}
      $disabled={!!disabled}
      $loading={!!loading}
      tabIndex={0}
      {...((!loading || !disabled) && { onClick })}
    >
      {!loading && children}

      {loading && (
        <LoadingWrapper>
          <LoadingIcon viewBox='0 0 1024 1024' focusable='false' width='20px' height='20px' fill='white'>
            <path d='M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z'></path>
          </LoadingIcon>
        </LoadingWrapper>
      )}
    </Container>
  )

  if (href) {
    return (
      <Link href={href} passHref $block={block} tabIndex={-1} onClick={href === 'intercom' ? openIntercom : undefined}>
        <Component />
      </Link>
    )
  }

  return <Component />
}

export default Button

type ButtonSchemeType = {
  [S in SchemeType]: {
    [T in ButtonVariant]: {
      background: ColorType
      hoverBackground: ColorType
      activeBackground: ColorType
      color: ColorType
    }
  }
}

const BUTTON_COLOR_SCHEME: ButtonSchemeType = {
  light: {
    primary: {
      background: 'blue',
      hoverBackground: 'blueHover',
      activeBackground: 'blueClick',
      color: 'white',
    },
    secondary: {
      background: 'charcoal5',
      hoverBackground: 'charcoal10',
      activeBackground: 'charcoal20',
      color: 'blue',
    },
  },
  dark: {
    primary: {
      background: 'blue',
      hoverBackground: 'blueHover',
      activeBackground: 'blueClick',
      color: 'white',
    },
    secondary: {
      background: 'white10',
      hoverBackground: 'white20',
      activeBackground: 'white30',
      color: 'white',
    },
  },
}

type StyledBtnProps = {
  $variant: ButtonVariant
  $size: ButtonSize
  $scheme: SchemeType
  $block: boolean
  $loading: boolean
  $disabled: boolean
}

const Container = styled.button<StyledBtnProps>`
  all: unset;
  width: ${({ $block }) => ($block ? '100%' : 'fit-content')};
  height: 44px;
  padding: 0 48px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: ${({ $disabled, $loading }) => ($disabled || $loading ? 'default' : 'pointer')};
  color: ${({ $variant, $scheme, theme }) => theme.colors[BUTTON_COLOR_SCHEME[$scheme][$variant].color]};
  transition: ${({ theme }) => theme.transitions.background};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  user-select: none;
  outline: none;
  ${({ theme }) => theme.typography.button.mobile}

  background: ${({ $variant, $scheme, theme }) => theme.colors[BUTTON_COLOR_SCHEME[$scheme][$variant].background]};

  :active {
    background: ${({ $variant, $scheme, theme }) =>
      theme.colors[BUTTON_COLOR_SCHEME[$scheme][$variant].activeBackground]};
  }

  :focus-visible::before {
    content: '';
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border: 2px solid ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
    border-radius: ${({ $size }) => ($size === 'large' ? '16px' : '14px')};
  }

  /* Desktop */
  @media ${({ theme }) => theme.breakpoints.md} {
    height: ${({ $size }) => ($size === 'large' ? '54px' : '42px')};
    padding: ${({ $size }) => ($size === 'large' ? '0 48px' : '0 14px')};
    border-radius: ${({ $size }) => ($size === 'large' ? '12px' : '10px')};
    ${({ theme }) => theme.typography.button.desktop}

    :hover {
      background: ${({ $variant, $scheme, theme }) =>
        theme.colors[BUTTON_COLOR_SCHEME[$scheme][$variant].hoverBackground]};
    }

    :active {
      background: ${({ $variant, $scheme, theme }) =>
        theme.colors[BUTTON_COLOR_SCHEME[$scheme][$variant].activeBackground]};
    }
  }
`

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: default;
  pointer-events: none;
`

const LoadingIcon = styled.svg`
  animation: rotate 0.8s infinite linear;
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 10px);

  path {
    stroke: white;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

const Link = styled(NextLink)<{ $block: boolean }>`
  width: ${({ $block }) => ($block ? '100%' : 'fit-content')};
`
