import { ColorType, SchemeType } from 'theme'

import NextLink from 'next/link'
import React from 'react'
import openIntercom from 'helpers/openIntercom'
import styled from 'styled-components'
import { useBlockScheme } from '../Block'

type NavigationVariant = 'primary' | 'secondary'

type Props = {
  id?: string
  className?: string
  variant?: NavigationVariant
  href: string
  scheme?: SchemeType
  block?: boolean
  children: string
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const NavigationLink = ({ id, className, variant = 'primary', href, scheme, block, children, onClick }: Props) => {
  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    onClick?.(e)
  }

  return (
    <Link
      id={id}
      className={className}
      href={href}
      passHref
      $scheme={colorScheme}
      $block={block}
      onClick={href === 'intercom' ? openIntercom : onClick ? handleClick : undefined}
    >
      <Container id={id} className={className}>
        <Content $scheme={colorScheme} $variant={variant}>
          {children}
        </Content>
      </Container>
    </Link>
  )
}

export default NavigationLink

type NavigationLinkSchemeType = {
  [S in SchemeType]: {
    color: ColorType
    hoverColor: ColorType
    activeColor: ColorType
  }
}

const LINK_COLOR_SCHEME: NavigationLinkSchemeType = {
  light: {
    color: 'charcoal',
    hoverColor: 'blueHover',
    activeColor: 'blueClick',
  },
  dark: {
    color: 'white',
    hoverColor: 'aquamarineHover',
    activeColor: 'aquamarineClick',
  },
}

type StyledLinkProps = {
  $scheme: SchemeType
  $block?: boolean
  $variant?: NavigationVariant
}

const Content = styled.div<StyledLinkProps>`
  width: ${({ $block }) => ($block ? '100%' : 'fit-content')};
  color: ${({ $scheme, theme }) => theme.colors[LINK_COLOR_SCHEME[$scheme].color]};
  transition: ${({ theme }) => theme.transitions.color};
  ${({ theme, $variant }) => theme.typography[$variant === 'secondary' ? 'body-small-400' : 'h4'].mobile}

  @media ${({ theme }) => theme.breakpoints.md} {
    ${({ theme, $variant }) => theme.typography[$variant === 'secondary' ? 'body-small-400' : 'button'].desktop}
  }

  :active {
    color: ${({ $scheme, theme }) => theme.colors[LINK_COLOR_SCHEME[$scheme].activeColor]};
  }
`

const Link = styled(NextLink)<{ $block?: boolean; $scheme: SchemeType }>`
  width: ${({ $block }) => ($block ? '100%' : 'fit-content')};

  :hover {
    ${Content} {
      color: ${({ $scheme, theme }) => theme.colors[LINK_COLOR_SCHEME[$scheme].hoverColor]};
    }
  }

  :focus-visible {
    outline: 2px solid ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
  }
`

const Container = styled.div``
