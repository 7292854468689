import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Burger = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
    <path d='M20 7L4 7' stroke={color} strokeWidth='2' />
    <path d='M20 12L4 12' stroke={color} strokeWidth='2' />
    <path d='M20 17L4 17' stroke={color} strokeWidth='2' />
  </svg>
)

export default Burger
