import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const RightArrow = ({ color }: Props) => (
  <svg width='16' height='30' viewBox='0 0 16 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.25 20L15.25 15L10.25 10'
      stroke={color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <line
      x1='14.125'
      y1='14.995'
      x2='1.375'
      y2='14.995'
      stroke={color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default RightArrow
