import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const PinAdd = ({ color }: Props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 20.7124L10.6367 18.3092C10.4931 18.0325 10.243 17.8375 9.95815 17.7547C6.88145 16.8601 4.625 13.9909 4.625 10.5845C4.625 6.45854 7.93308 3.125 12 3.125C16.0669 3.125 19.375 6.45854 19.375 10.5845C19.375 13.9909 17.1186 16.8601 14.0418 17.7547C13.757 17.8375 13.5069 18.0325 13.3633 18.3092L12 20.7124Z'
      stroke={color}
      strokeWidth='1.25'
    />
    <path d='M9.17651 10.5801H14.8236' stroke={color} strokeWidth='1.25' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M11.9954 7.7251V13.4251' stroke={color} strokeWidth='1.25' strokeMiterlimit='10' strokeLinecap='round' />
  </svg>
)

export default PinAdd
