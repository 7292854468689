import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Cross = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
    <path stroke={color} strokeWidth='2' d='M6.797 17.5L17.5 6.5M17.203 17.5L6.5 6.5'></path>
  </svg>
)

export default Cross
