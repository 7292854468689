import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Photo = ({ color }: Props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 15.9759C13.6569 15.9759 15 14.6425 15 12.9977C15 11.3529 13.6569 10.0195 12 10.0195C10.3431 10.0195 9 11.3529 9 12.9977C9 14.6425 10.3431 15.9759 12 15.9759Z'
      stroke={color}
      strokeWidth='1.25'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M6.81006 9.74454C6.81006 9.61711 6.92275 9.5 7.06006 9.5C7.19737 9.5 7.31006 9.61711 7.31006 9.74454C7.31006 9.87198 7.19737 9.98908 7.06006 9.98908C6.92275 9.98908 6.81006 9.87197 6.81006 9.74454Z'
      fill={color}
      stroke={color}
    />
    <path
      d='M18 6.48544C19.1 6.48544 20 7.37889 20 8.47088V17.5146C20 18.6066 19.1 19.5 18 19.5H6C4.9 19.5 4 18.6066 4 17.5146V8.47088C4 7.37889 4.9 6.48544 6 6.48544H7.22C7.75 6.48544 8.26 6.2869 8.63 5.92952L9.54 5.05592C9.92 4.69854 10.42 4.5 10.95 4.5H13.02C13.55 4.5 14.06 4.69854 14.43 5.05592L15.34 5.92952C15.72 6.2869 16.22 6.48544 16.75 6.48544H17.97H18Z'
      stroke={color}
      strokeWidth='1.25'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
  </svg>
)

export default Photo
