import { ColorType, SchemeType } from 'theme'
import React, { MouseEvent } from 'react'

import Icon from '../Icon'
import styled from 'styled-components'
import { useBlockScheme } from '../Block'

type Props = {
  className?: string
  scheme?: SchemeType
  type: 'menu' | 'close'
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
}

const IconButton = ({ className, scheme, type = 'menu', onClick }: Props) => {
  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme

  return (
    <Container className={className} $scheme={colorScheme} onClick={onClick}>
      {type === 'menu' ? (
        <Burger name='burger' color={MENU_BUTTON_COLOR_SCHEME[colorScheme].color} />
      ) : (
        <Icon name='cross' color={MENU_BUTTON_COLOR_SCHEME[colorScheme].color} />
      )}
    </Container>
  )
}

export default IconButton

type MenuButtonSchemeType = {
  [S in SchemeType]: {
    background: ColorType
    hoverBackground: ColorType
    activeBackground: ColorType
    color: ColorType
  }
}

const MENU_BUTTON_COLOR_SCHEME: MenuButtonSchemeType = {
  light: {
    background: 'charcoal5',
    hoverBackground: 'charcoal10',
    activeBackground: 'charcoal20',
    color: 'charcoal',
  },
  dark: {
    background: 'white10',
    hoverBackground: 'white20',
    activeBackground: 'white30',
    color: 'white',
  },
}

const Burger = styled(Icon)``

const Container = styled.button<{ $scheme: SchemeType }>`
  all: unset;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  outline: none;
  background: ${({ $scheme, theme }) => theme.colors[MENU_BUTTON_COLOR_SCHEME[$scheme].background]};
  transition: ${({ theme }) => theme.transitions.default};

  :hover {
    background: ${({ $scheme, theme }) => theme.colors[MENU_BUTTON_COLOR_SCHEME[$scheme].hoverBackground]};

    ${Burger} {
      path {
        transition: ${({ theme }) => theme.transitions['menu-button']};
      }

      path:nth-child(1) {
        d: path('M20 6L4 6');
      }
      path:nth-child(2) {
        d: path('M20 12L4 12');
      }
      path:nth-child(3) {
        d: path('M20 18L4 18');
      }
    }
  }

  :active {
    background: ${({ $scheme, theme }) => theme.colors[MENU_BUTTON_COLOR_SCHEME[$scheme].activeBackground]};
  }

  :focus-visible::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 23px;
    border: 2px solid ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
    border-radius: 15px;
  }
`
