import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Lock = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
    <path
      fill={color}
      fillRule='evenodd'
      d='M9.189 5.558c.215-.22.502-.35.811-.35.619 0 1.177.541 1.177 1.269v1.002H8.823V6.477c0-.367.143-.69.366-.919zM7.573 7.482V6.477c0-.697.274-1.332.72-1.79A2.378 2.378 0 0110 3.957c1.367 0 2.427 1.16 2.427 2.519v1.005c1.039.05 1.74.973 1.74 1.939v4.049c0 .992-.74 1.947-1.836 1.947H7.664c-1.09 0-1.83-.948-1.83-1.942V9.426c0-.963.697-1.89 1.74-1.944zm2.877 4.28a.851.851 0 00.45-.743c0-.474-.402-.858-.9-.858s-.901.384-.901.858c0 .318.181.595.45.743v.973h.901v-.973z'
      clipRule='evenodd'
    ></path>
  </svg>
)

export default Lock
