import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const X = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M18.326 2H21.7l-7.37 8.472L23 22h-6.789l-5.317-6.992L4.81 22H1.434l7.883-9.062L1 2h6.961l4.806 6.39L18.327 2zm-1.184 17.97h1.87L6.945 3.923H4.94L17.142 19.97z'
    ></path>
  </svg>
)

export default X
