import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const CaretDown = ({ color }: Props) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M13 8.5L10 11.5L7 8.5' stroke={color} strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

export default CaretDown
