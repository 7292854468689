import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const On = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
    <path
      fill={color}
      d='M9.368 9.99c0 .518-.095.963-.284 1.336-.19.37-.45.656-.78.857a2.119 2.119 0 01-1.119.298c-.42 0-.795-.1-1.124-.298a2.088 2.088 0 01-.777-.86C5.094 10.951 5 10.507 5 9.991c0-.518.095-.962.284-1.333.19-.373.448-.658.777-.857.33-.2.704-.301 1.124-.301.418 0 .792.1 1.12.3.33.2.59.485.779.858.19.37.284.815.284 1.333zm-.725 0c0-.394-.063-.726-.191-.996a1.4 1.4 0 00-.521-.616c-.22-.14-.468-.21-.746-.21-.28 0-.529.07-.748.21a1.42 1.42 0 00-.52.616c-.127.27-.19.602-.19.997 0 .394.063.727.19.999.127.27.3.475.52.615.22.139.469.208.748.208.278 0 .526-.069.746-.208.22-.14.394-.346.52-.615.129-.272.192-.605.192-1zM14.193 7.566v4.849h-.672l-2.465-3.556h-.044v3.556h-.732V7.566h.677l2.467 3.56h.045v-3.56h.724z'
    ></path>
  </svg>
)

export default On
