import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Menu = ({ color }: Props) => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='40' height='40' rx='10' fill={color} fillOpacity='0.05' />
    <path d='M28 20L12 20' stroke={color} strokeWidth='2' />
    <path d='M28 25L12 25' stroke={color} strokeWidth='2' />
    <path d='M28 15L12 15' stroke={color} strokeWidth='2' />
  </svg>
)

export default Menu
