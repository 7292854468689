import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const CaretRight = ({ color }: Props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10 7L15 12L10 17' stroke={color} strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

export default CaretRight
