import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Signature = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' fill='none' viewBox='0 0 25 24'>
    <path stroke={color} strokeLinecap='round' strokeMiterlimit='10' strokeWidth='1.25' d='M20.264 18.5H4.334'></path>
    <path
      stroke={color}
      strokeMiterlimit='10'
      strokeWidth='1.25'
      d='M15.206 15.055l1.902-1.578.06-.06 3.086-6.408a.787.787 0 00-.368-1.055l-.807-.382a.803.803 0 00-1.055.382l-3.087 6.409c-.02.02-.02.08-.02.08l-.03 2.46c-.01.172.19.262.319.152z'
    ></path>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.25'
      d='M12.538 15.055h-4.8L6.037 11.82l-1.703 3.235'
    ></path>
  </svg>
)

export default Signature
