import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const CaretDownSmall = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
    <path stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.25' d='M7 8.5l3 3 3-3'></path>
  </svg>
)

export default CaretDownSmall
