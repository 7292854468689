import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Tick = ({ color }: Props) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M3.5 10L7.83333 14L16.5 6' stroke={color} strokeWidth='2' strokeLinejoin='round' />
  </svg>
)

export default Tick
