import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Form = ({ color }: Props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19.0032 4H11.0181C10.4676 4 10.0212 4.44772 10.0212 5V6.59C10.0212 7.14228 10.4676 7.59 11.0181 7.59H19.0032C19.5538 7.59 20.0001 7.14228 20.0001 6.59V5C20.0001 4.44772 19.5538 4 19.0032 4Z'
      stroke={color}
      strokeWidth='1.25'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M19.0032 10.52H11.0181C10.4676 10.52 10.0212 10.9677 10.0212 11.52V13.11C10.0212 13.6623 10.4676 14.11 11.0181 14.11H19.0032C19.5538 14.11 20.0001 13.6623 20.0001 13.11V11.52C20.0001 10.9677 19.5538 10.52 19.0032 10.52Z'
      stroke={color}
      strokeWidth='1.25'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M16.5 16.5V18C16.5 19.1046 15.6046 20 14.5 20H6C4.89543 20 4 19.1046 4 18V7.5C4 6.39543 4.89543 5.5 6 5.5L8 5.5'
      stroke={color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Form
