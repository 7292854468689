import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Payment = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'>
    <rect
      width='32'
      height='24'
      x='5.25'
      y='14.76'
      stroke={color}
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
      rx='4'
    ></rect>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
      d='M5.25 20.76h32M22.25 9.26h12.5a8 8 0 018 8v10.5M21.75 28.26h9.5M21.75 32.26h4.75'
    ></path>
    <circle
      cx='13.75'
      cy='30.26'
      r='2.5'
      stroke={color}
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
    ></circle>
  </svg>
)

export default Payment
