import { ColorType } from 'theme'
import React from 'react'

type Props = {
  color?: ColorType
}

const Facebook = ({ color }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M23 12.067C23 5.955 18.075 1 12 1S1 5.955 1 12.067C1 17.591 5.023 22.17 10.281 23v-7.734H7.488v-3.199h2.793V9.63c0-2.774 1.643-4.306 4.155-4.306 1.203 0 2.462.216 2.462.216v2.724h-1.387c-1.366 0-1.792.853-1.792 1.73v2.074h3.05l-.487 3.2h-2.563V23C18.977 22.17 23 17.591 23 12.067z'
    ></path>
  </svg>
)

export default Facebook
